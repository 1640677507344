.hero-container{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 25.5vh;
    background-image: url(/public/image/PC-Pic-1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    max-width: 1920px;
    width: 100%;
    max-height: 680px;
    height: 680px;
}
.hero_button{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: end;
    position: relative;
    margin-bottom: 150px;
    margin-right: 75px;
    
}
@media  (max-width:1440px) {
    .hero-container {
        height: 510px;
        margin-top: 22vh;
    }
    
    .hero_button{
        margin-right: -10px;
        margin-bottom: 110px;      
    }
    .hero_button img {
        width: 80%;
    }
}
@media  (max-width:1280px) {
    .hero-container {
        height: 453px;
        margin-top: 26vh;
    }
    .hero_button {
        margin-right: -50px;
    }
    .hero_button img {
        width: 65%;
    }
}
@media  (min-width:1024px) and (max-width:1250px) {
    .hero-container {
        margin-top: 13vh;
        height: 363px;
    }
    .hero_button{
        margin-top: 30px;
        margin-right: -100px;
    }
    .hero_button img {
        width: 55%;
    }
    .content_text {
        font-size: 12px;
    }
}
@media  (max-width:810px) {
    .hero-container {
        display: flex;
        align-items: end;
        justify-content: center;
        margin-top: 10vh;
        background-image: url(/public/image/Phone-Pic-1.jpg);
        background-size: contain;
        max-height: 1182px;
        height: 1182px;
        width: 100%;
    }
    .hero_button{
        width: 100%;
        gap: 0;
        flex-direction: row;
        margin: 0;
        margin-bottom: 100px;
    }
    .hero_button img {
        width: 100%;
    }
}
@media  (max-width:768px) {
    .hero-container {
        margin-top: 17vh;
        height: 1117px;
    }
    .hero_button {
        margin-bottom: 90px;
    }
}
@media  (max-width:480px) {
    .hero-container{
        height: 624px;
        width: 100%;
        margin-top: 7.5vh;
    }
    .hero_button {
        width: 75%;
        padding-bottom: 50px;
        margin: 0 auto;
    }
}
@media  (max-width:414px) {
    .hero-container{
        height: 603px;
        margin-top: 7vh;
    }
    .hero_button {
        padding-bottom: 60px;
    }
}
@media  (max-width:390px) {
    .hero-container{
        height: 570px;
        margin-top: 6vh;
    }
    .hero_button {
        padding-bottom: 50px;
    }
}
@media (max-width:365px) {
    .hero-container {
        height: 548px;
        margin-top: 5vh;
    }
}
