.contact_con {
    background-color: #140101;
}
.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 60px 0;
}
.contact_logo{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact_logo:hover{
    animation: bounce 2s infinite;
}

@media (max-width:480px) {
   .contact a{
    width: 60%;
   }
   .contact a img{
    width: 100%;
   }
   .qr_contact {
    width: 30%;
   }
   .qr_contact img {
    width: 100%;
   }
}
@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.2);
    }
}